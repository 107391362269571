const http = 'https://cqgdrm.com';

export function getHttp(){
  return http;
}


export function getApi(){
  return http + `/api/website`;
}

export function getFile() {
  return http + `/file`;
}


/**
 * 测试接口
 * @returns 
 */
// const http = 'http://localhost';

// export function getHttp() {
//   return http;
// }

// export function getApi() {
//   return http + `:8082/api/website`;
// }

// export function getFile() {
//   return http + `:8050`;
// }
