import axios from "axios";
import { getApi } from "./http";
// import VueAxios from "vue-axios";
// Vue.use(VueAxios,axios)

axios.defaults.baseURL = getApi();
// Axios.defaults.baseURL = '/api'
// axios.defaults.headers.post['Content-Type'] = 'application/json';

axios.interceptors.request.use(
  function(config) {
    // 在发送请求之前做些什么
    // 后台需要前端这边传相关的参数（在请求头添加参数）
    // Tokey
    // userId
    // sui
    // 业务需求
    // 最终目地不是在请求头添加参数
    return config;
  },
  function(error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

/**
 * 请求接口后，返回数据进行拦截（响应拦截器）
 */
axios.interceptors.response.use(
  function(response) {
    // 对响应数据做点什么
    let res = response.data;
    // 业务需求
    if (res.code !== 0) {
      return Promise.reject(res);
    } else {
      return res.data;
      // return Promise.resolve(data);
    }
  },
  function(error) {
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

export default axios;
// const _this = this//因为在axios用的this是里面的this，是return的对象，不是前面自己所指的对象。
// //get是后端的数据链接，改为自己的链接
//     axios.get('http://localhost:8181/s_information/findAll').then(function (resp) {
//       // console.log(resp.data)，在页面控制台输出数据
//       _this.students = resp.data
//     })
